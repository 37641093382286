import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginCloneComponent } from './login-clone/login-clone.component';
import { RegisterComponent } from './register/register.component';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { HomeComponent } from './home/home.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        DataTablesModule
    ],
    declarations: [LoginCloneComponent, RegisterComponent, HomeComponent],
    exports: []
})
export class AppUserModule {}