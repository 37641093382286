import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../app-auth/services/authentication.service';
//import { AlertService } from '../../app-alert/services/alert.service';
import { first } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { UserService } from '../services/user.service';
import { User } from 'src/app/_models/user';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login-clone',
  templateUrl: './login-clone.component.html',
  styleUrls: ['./login-clone.component.css']
})
export class LoginCloneComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string = "/calendar";

  loginRequest: any = { "username": "", "password": "" };

  constructor(
    private formBuider: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toast: ToastrService,
    private userService: UserService,

    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    // redirect to home if already logged in
    // if (this.authenticationService.currentUserValue) {
    //   this.router.navigate(['/']);
    // }
  }

  ngOnInit() {
    // localStorage.removeItem("loaded");
    this.loginForm = this.formBuider.group({
      username: [this.loginRequest["username"], Validators.required],
      password: [this.loginRequest["password"], Validators.required]
    })

    //get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/calendar';
    //add class 
    this.renderer.addClass(this.document.body, 'login-page');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'login-page');
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    //reset alerts on submit


    //stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    //this.loading = true;

    console.log("Username: " + this.f.username.value + " | Password: " + this.f.password.value);
    this.userService.login(this.f.username.value, this.f.password.value).subscribe(response => {
      if (response["code"] == 200) {
        localStorage.setItem('accessToken', response["data"].accessToken);
        this.userService.setCurrentUser()
          .pipe(first())
          .subscribe(
            response => {
              if (response['code'] == 200) {
                let currentUser: User;
                currentUser = response['data'].user;
                if (currentUser.tuvan) {
                  if (currentUser.role_tuvan) {
                    currentUser.isAdmin = true;
                    localStorage.setItem('currentUser', JSON.stringify(currentUser));
                    this.router.navigate([this.returnUrl]);
                  }else  this.toast.error('Chưa có doanh nghiệp để tư vấn!', 'Thông báo', { timeOut: 2000 });
                } else {
                  localStorage.setItem('currentUser', JSON.stringify(currentUser));
                  this.router.navigate([this.returnUrl]);
                }
              }

            },
            error => {
              console.log(error);
              this.toast.error('Đăng nhập không thành công!', 'Thông báo', { timeOut: 2000 });
              //this.loading = false;
            }
          );
      } else {
        this.toast.error('Đăng nhập không thành công!', 'Thông báo', { timeOut: 2000 });
        //this.loading = false;
      }
    });

  }

}
