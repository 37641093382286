import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppAlertModule } from './app-alert/app-alert.module';
import { AppAuthModule } from './app-auth/app-auth.module';
import { AppUserModule } from './app-user/app-user.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfigService } from './_services/config';
import { JwtInterceptor } from './app-auth/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './app-auth/interceptors/error.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfirmComponent } from './confirm/confirm.component';
import { ConfirmService } from './confirm/confirm.service';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableModule, MatPaginatorModule, MatSortModule, MatPaginatorIntl } from '@angular/material';
import { getVietnamPaginatorIntl } from 'src/vietnam_paginator';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { SearchPipe } from './search.pipe';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmComponent,
    SearchPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppAuthModule,
    AppUserModule,
    AppAlertModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FullCalendarModule,
    NgSelectModule, FormsModule, NgbModule,
    MatTableModule, MatPaginatorModule,
    MatSortModule, DlDateTimeDateModule,
    DlDateTimePickerModule,
  ],

  providers: [
    ConfigService, ConfirmService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: getVietnamPaginatorIntl },
    DatePipe
  ], entryComponents: [ConfirmComponent],

  bootstrap: [AppComponent]
})
export class AppModule { }

