import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../../_models/user';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ConfigCommon } from 'src/app/_common/config-common';
import { UserDetail } from '../request/UserDetail';
import { first } from 'rxjs/operators';
import { Data } from '../list-user/user';
import { Company } from 'src/app/_models/company';

@Injectable({ providedIn: 'root' })
export class UserService {
    private url = ConfigCommon.config['HOST_API'];
    constructor(private http: HttpClient) { }

    getAll() {
        let url: string = this.url + "/secure/user";
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return this.http.get(url, {
            headers: new HttpHeaders({
                'Authorization': currentUser['accessToken']
            })
        });
    }

    getUserByCom() {
        let url: string = this.url + "/secure/subusers";
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return this.http.get(url, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${currentUser}`
            })
        });
    }
    public getAllCompany() {
        let url: string = this.url + "/secure/usercompany?com_id=" + localStorage.getItem('com_id');
        const currentUser = localStorage.getItem("accessToken");
        return this.http.get(url, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${currentUser}`
            })
        });
    }
    register(user: User) {
        // return this.http.post(`${environment.apiUrl}/users/register`, user);
    }

    login(username: String, password: String) {
        let loginRequest = { "username": username, "password": password };
        return this.http.post(this.url + "/public/login", loginRequest);
    }

    createUser(userRequest: UserDetail) {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return this.http.post(this.url + "/public/user", userRequest);
        // headers: new HttpHeaders({
        //     'Authorization': currentUser['accessToken'],
        //     'content-type': 'application/json'
        //   })
        // return this.http.post(this.url, userRequest, this.jwt()).map((response: Response) => response.json());
    }

    delete(id: String) {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return this.http.delete(this.url + "/" + id, {
            headers: new HttpHeaders({
                'accessToken': currentUser['accessToken'],
                'content-type': 'application/json'
            })
        });
    }

    setCurrentUser() {
        return this.http.get(this.url + '/secure/currentUser');
    }

    getCurrentUser(): User {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    getCurrentCompany(): Company {
        return JSON.parse(localStorage.getItem('company'));
    }

    logout() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('currentUser');
    }

    public update(id: string, password: string) {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let url: string = this.url + "/secure/usercompany";
        return this.http.put(url, { "id": id, "password": password }, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${currentUser}`
            })
        });
    }

    getDetailUser() {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return this.http.get(this.url + '/secure/currentUser', {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${currentUser}`
            })
        });
        // this.setCurrentUser()
        //   .pipe(first())
        //   .subscribe(
        //     response => {
        //       if (response['code'] == 200) {
        //         let currentUser: User;
        //         currentUser = response['data'].user;
        //         if (currentUser.tuvan) {
        //           if (currentUser.role_tuvan) {
        //             localStorage.setItem('currentUser', JSON.stringify(currentUser));
        //             this.router.navigate([this.returnUrl]);
        //           }else  this.toast.error('Chưa có doanh nghiệp để tư vấn!', 'Thông báo', { timeOut: 2000 });
        //         } else {
        //           localStorage.setItem('currentUser', JSON.stringify(currentUser));
        //           this.router.navigate([this.returnUrl]);
        //         }
        //       }

        //     },
        //     error => {
        //       console.log(error);
        //       this.toast.error('Đăng nhập không thành công!', 'Thông báo', { timeOut: 2000 });
        //       //this.loading = false;
        //     }
        //   );
    }

    getCompany() {
        let url: string = this.url + "/secure/company";
        const currentUser = localStorage.getItem("accessToken");
        return this.http.get(url, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${currentUser}`
            })
        });
    }
    createU(t: Data) {
        let url: string = this.url + "/secure/register";
        const currentUser = localStorage.getItem("accessToken");
        return this.http.post(url, t, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${currentUser}`
            })
        });
    }
    updateU(t: Data) {
        let url: string = this.url + "/secure/usercompany";
        const currentUser = localStorage.getItem("accessToken");
        return this.http.put(url, t, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${currentUser}`
            })
        });
    }
    deleteU(t) {
        let url: string = this.url + "/secure/usercompany/" + t;
        const currentUser = localStorage.getItem("accessToken");
        return this.http.delete(url, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${currentUser}`
            })
        });
    }
    public changepass(id: string, password: string, passwordold: string) {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let url: string = this.url + "/secure/changepassword";
        return this.http.put(url, { "id": id, "password": password, "old_password": passwordold }, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${currentUser}`
            })
        });
    }
    //http://3.1.20.61:8080/secure/findOneCompany?com_id=6804A10805BF47E49BD5E81074D4C8FB
    getCompanyDetail() {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let url: string = this.url + "/secure/findOneCompany?com_id=" + localStorage.getItem("com_id");
        return this.http.get(url, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${currentUser}`
            })
        });
    }
}