import { Component } from '@angular/core';
import { User } from './_models/user';
import { Router } from '@angular/router';
import { AuthenticationService } from './app-auth/services/authentication.service';
import { ConfigService } from './_services/config';
import { ConfigCommon } from './_common/config-common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  currentUser: String[];
  // currentUser: User;
  title = 'KPIManagement';

  constructor(
    private router: Router,
    private configService: ConfigService
  ){
    ConfigCommon.config = configService.loadJSON('./assets/config/config.json');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  logout(){
    this.router.navigate(['/login']);
  }
}
