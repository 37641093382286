import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginCloneComponent } from './app-user/login-clone/login-clone.component';
import { RegisterComponent } from './app-user/register/register.component';
import { HomeComponent } from './app-user/home/home.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'login',
    component: LoginCloneComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: '',
    //loadChildren: () => import('./manager/manager.module').then(mod => mod.ManagerModule)
    loadChildren:'./manager/manager.module#ManagerModule'
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'home' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
