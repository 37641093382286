import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/app-auth/services/authentication.service';
import { UserService } from 'src/app/app-user/services/user.service';
import { AlertService } from 'src/app/app-alert/services/alert.service';
import { first } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { ConfirmService } from 'src/app/confirm/confirm.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuider: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private aService: AlertService,
    private toast: ToastrService, private cService: ConfirmService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    // redirect to home if already logged in
    // if (this.authenticationService.currentUserValue) {
    //   this.router.navigate(['/']);
    // }
  }

  ngOnInit() {
    this.registerForm = this.formBuider.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      position: [''],
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      name_company: ['', Validators.required],
      address: ['', Validators.required],
      phone_company: [''],
      email_company: [''],
      business: ['', Validators.required],
      lawyer: [''],
      tax_code: [''],
      stock_code: ['']
    }, {
      validators: MustMatch('password', 'confirmPassword')
    });

    //add class 
    this.renderer.addClass(this.document.body, 'login-page');
  }

  ngOnDestroy(): void {
    //remove class
    this.renderer.removeClass(this.document.body, 'login-page');
  }
  //convenience getter for easy access to from fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    //stop here if from is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;

    this.userService.createUser(this.registerForm.value).subscribe(
      response => {
        console.log(response);
        if (response["code"] == 200) {
          // this.toast.success('Bạn đã đăng ký thành công. Chờ bộ phận kiểm duyệt thông tin doanh nghiệp trước khi thực hiện đăng nhập hệ thống này! ', "Thông báo", { timeOut: 2000 });
          this.aService.success("Bạn đã đăng ký thành công. Chờ bộ phận kiểm duyệt thông tin doanh nghiệp trước khi thực hiện đăng nhập hệ thống này! ", true)
          this.router.navigate(['/login']);
        } else {
          this.toast.error("Lỗi: "+response["message"], "Thông báo", { timeOut: 2000 })
          // this.alertService.error(response["message"]);
          this.loading = false;
        }

      },
      error => {
        // this.alertService.error(error);
        this.toast.error("Lỗi", "Thông báo", { timeOut: 2000 })
        this.loading = false;
      }
    );
  }

}
